import Vue from 'vue';
import VueRouter, {RouteConfig} from 'vue-router';

Vue.use(VueRouter);
import bootstrap from 'modules/bootstrap';

const bootstrapRoute = bootstrap.getRoutes();
const routes: Array<RouteConfig> = [
    {
        path: '/linerp',
        alias: '/provider/:providerId?',
        component: () => import('@/layout/member/member.vue'),
        children: [
            ...bootstrapRoute.public,
            ...bootstrapRoute.member,
            {
                path: '404',
                name: '404',
                component: () => import('modules/base/views/public404.vue'),
            },
        ],
    },
    {
        path: '/provider-store/:providerId?',
        component: () => import('@/layout/providerStore/providerStore.vue'),
        children: [...bootstrapRoute.store],
    },
    {
        path: '/test',
        component: () => import('@/modules/base/views/test.vue'),
    },
    {
        path: '/store.linerp.co',
        component: () =>
            import('@/modules/FrontProvider/layouts/FrontProviderLayout.vue'),
        children: [...bootstrapRoute.FrontProvider],
    },
    {
        path: '/line-relation',
        component: () => import('@/modules/base/views/lineRelationCallback.vue'),
    },

    // {
    //   path: '/*',
    //   redirect: '/404',
    // },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach((to, from, next) => {
    if (window.rootVm && window.vueRouteChangePrevent) {
        // 前台的表單先不擋
        // if(window.vueRouteChangePrevent.hasPrevent()) {
        //   const confirmResult = confirm(window.rootVm.$t('exit.confirm'))
        //   if(!confirmResult) return
        // }
        window.vueRouteChangePrevent.reset();
    }

    next()
})

export default router;
